import { Outlet } from "react-router-dom";
import AuthGuard from "../../components/AuthGuard";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Backdrop from "../../components/Backdrop";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";
import publicAxios from "../../utils/publicAxios";
import { useDispatch } from "react-redux";
import { setServices } from "../../data/slices/servicesSlice";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const MainLayout = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const dispatch= useDispatch()
  useEffect(()=>{
    console.log(currentLanguageCode)
    publicAxios.get(`/home/get_services?lang=${currentLanguageCode}`).then((res)=>{
      dispatch(setServices(res.data.service))
    })
  },[currentLanguageCode,])


  return (
    // <AuthGuard>
    <div className="main_layout">
      <Sidebar />
      <div className="main_layout__container">
        <Header />
        <Outlet />
        <Footer />
      </div>
      <Backdrop />
    </div>
    // </AuthGuard>
  );
};

export default MainLayout;
